body {
    background: #2dbcb0;
}
#root {
    background: white;
}

html.loading body,
html.no-js body {
    font: 14px/1.6em sans-serif;
    color: black;
}

@keyframes -loading-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

html.loading body:before {
    animation: -loading-spin 1.5s ease-in-out 0s infinite both;
    content: "";
    display: block;
    border-radius: 50%;
    border: 5px solid #A0E1DB;
    border-left-color: transparent;
    border-right-color: transparent;
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    right: calc(50% - 50px);
}

#root {
    transition: opacity 200ms ease-in 200ms;
    opacity: 0;
}

html.loaded #root {
    opacity: 1;
}

html.loading #browser-support,
html.loaded #browser-support {
    display: none;
}
html.error #browser-support {
    animation: none;
}

@keyframes fadeIn {
    from { opacity: 0; }
    70% { opacity: 0;}
    to { opacity: 1; }
}

#browser-support {
    animation-name: fadeIn;
    animation-duration: 5s;
    text-align: center;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    position: absolute;
    border-radius: 9px;
    top: 100px;
    left: 0;
    right: 0;
    background: white;
}

#browser-support h1 {
    font-size: 35px;
    line-height: 1em;
    color: #345273;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

#browser-support p  {
    color: #2c2c2c;
}

#browser-support .user-agent {
    color: #666666;
    font-size: 10px;
}

#browser-support p.error-message {
    color: #ee6c5f;
}

#browser-support img {
    display: block;
    margin: auto;
}
